import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  adminCreateSubAccount,
  adminUpdateAccount,
  // requestCloseAccount,
  adminCheckAccountPasswordChangeStatus,
  clearSuccessErrorProps,
} from "../../../redux/actions/adminActions";
import { randomPasswordComplex } from "../../../utils/utils";

const AccountDetails = props => {
  const [state, setState] = useState({
    addAccountErrors: "",
    showAddEditAccountDetailsModal: false,
    loading: true,
    updating: false,
    platform: "MT5",
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [requestDemoAccount, setRequestDemoAccount] = useState(false);
  // const [baseCurrency, setBaseCurrency] = useState(null);
  // const [platform, setPlatform] = useState("MT5");

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      clearSuccessErrorProps();

      let closeModal = true;

      if (props.success.leverageChangeRequested) {
        props.createNotification("leverageChangeRequested");
      }
      if (props.success.accountRequested) {
        // closeModal = false;
        props.createNotification("accountRequested");
      }
      if (props.success.passwordChanged) {
        closeModal = false;
        setTimeout(
          () =>
            props.adminCheckAccountPasswordChangeStatus(
              props.success.accountId
            ),
          5000
        );
      }
      // @TODO: FIX THIS!!1
      // if (props.success.passwordChangeChecked) {
      //   if (props.success.data.status === "approved")
      //     props.createNotification("passwordChanged");
      //   else props.createNotification("passwordChangeFailed");
      // }

      if (closeModal) {
        setBtnDisabled(false);
        props.handleModalClose("showAddEditAccountDetailsModal");
        props.triggerGetAccounts();
      }
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.account !== undefined
    ) {
      clearSuccessErrorProps();

      setState(prevState => {
        return {
          ...prevState,
          addAccountErrors: props.errors.account,
        };
      });
      setBtnDisabled(false);
    }
  }, [props.success, props.errors]);

  // const handleChangeBaseCurrency = e => {
  //   setBaseCurrency(e.target.value);
  // };

  const handleRequestAnAccount = () => {
    let requestType = document.getElementById("newRequestType").value;

    const accData = {
      userId: props.userId,
      requestType,
      // nickname: document.getElementById("newNickname").value,
      // platform: document.getElementById("newPlatform").value,
      platform: state.platform,
      accType: document.getElementById("newAccType").value,
      baseCurrency: document.getElementById("newBaseCurrency").value,
      leverage: document.getElementById("newLeverage").value,
    };

    if (requestDemoAccount) {
      accData.balance = document.getElementById("newBalance").value;
    }

    if (
      // accData.platform === "" ||
      accData.accType === "" ||
      accData.baseCurrency === "" ||
      accData.leverage === "" ||
      (requestDemoAccount && accData.balance === "")
    ) {
      return false;
    }

    setBtnDisabled(true);

    props.adminCreateSubAccount(accData);
  };

  const handleEditAccountDetails = () => {
    const accData = {
      accountGroup: props.account.accountGroup,
      accountId: props.account._id,
      accountType: props.account.type,
      oldLeverage: props.account.leverage,
      leverage: parseInt(document.getElementById("editLeverage").value),
      password: document.getElementById("editPassword").value,
    };

    if (
      accData.password.toString() === props.account.password.toString() &&
      accData.leverage.toString() === props.account.leverage.toString()
    ) {
      return false;
    }
    setBtnDisabled(true);
    props.adminUpdateAccount(accData);
  };

  const handleRequestDemoAccount = () => {
    setRequestDemoAccount(
      document.getElementById("newRequestType").value === "demo"
    );
  };

  const handlePasswordChange = () => {
    document.getElementById("editPassword").value = randomPasswordComplex(10);
  };

  // const handleSelectPlatform = e => {
  //   setPlatform(e.target.value);
  // };

  // const handleRequestCloseAccount = accountId => {
  //   if (window.confirm("Do you want to request to close this account?")) {
  //     props.requestCloseAccount(accountId);
  //   }
  // };

  return typeof props.account === "undefined" ? (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditAccountDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>New account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newRequestType">
          <Form.Label>Request account</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            onChange={handleRequestDemoAccount}
            required
          >
            <option value="live">Live</option>
            <option value="demo">Demo</option>
          </Form.Control>
        </Form.Group>

        {requestDemoAccount ? (
          <Form.Group controlId="newBalance">
            <Form.Label>Balance</Form.Label>
            <Form.Control as="select" className="roundedBox" required>
              <option value="">Select balance</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="5000">5000</option>
              <option value="10000">10000</option>
              <option value="25000">25000</option>
              <option value="50000">50000</option>
            </Form.Control>
          </Form.Group>
        ) : null}

        {/*<Form.Group controlId="newNickname">*/}
        {/*  <Form.Label>Nickname (optional)</Form.Label>*/}
        {/*  <Form.Control type="text" />*/}
        {/*</Form.Group>*/}

        <Form.Group controlId="newPlatform">
          <Form.Label>Platform: MT5</Form.Label>
        </Form.Group>

        <Form.Group controlId="newBaseCurrency">
          <Form.Label>Base currency</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            // onChange={e => handleChangeBaseCurrency(e)}
            required
          >
            <option value="">Select base currency</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="USD">USD</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newAccType">
          <Form.Label>Account Type</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="">Select account type</option>
            <option value="Standard">Standard</option>
            <option value="ECN">ECN</option>
            {/*{!requestDemoAccount &&*/}
            {/*state.platform === "MT4" &&*/}
            {/*baseCurrency === "USD" ? (*/}
            {/*  <option value="Swap Free">Swap Free</option>*/}
            {/*) : null}*/}
            {!requestDemoAccount && state.platform === "MT5" ? (
              <option value="Mini FX">Mini FX</option>
            ) : null}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newLeverage">
          <Form.Label>Leverage</Form.Label>
          <Form.Control as="select" className="roundedBox" required>
            <option value="50">50:1</option>
            <option value="100">100:1</option>
            <option value="200">200:1</option>
            <option value="300">300:1</option>
            <option value="400">400:1</option>
            <option value="500">500:1</option>
          </Form.Control>
        </Form.Group>

        <Form.Text style={{ color: "red" }}>
          {props.errors.server}
          {state.addAccountErrors}
        </Form.Text>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditAccountDetailsModal")
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="darkBlueButton"
          style={{
            fontWeight: "normal",
          }}
          disabled={btnDisabled}
          onClick={handleRequestAnAccount}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={true}
      onHide={() => props.handleModalClose("showAddEditAccountDetailsModal")}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Account Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*<Form.Group controlId="editNickname">*/}
        {/*  <Form.Label>Nickname (optional)</Form.Label>*/}
        {/*  <Form.Control type="text" defaultValue={props.account.nickname} />*/}
        {/*</Form.Group>*/}

        <Form.Group>
          <Form.Label>Platform</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.platform}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Account Number</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.number}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Base currency</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.baseCurrency}
            disabled
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Account Type</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.type}
            disabled
          />
        </Form.Group>

        {/*<Form.Group controlId="editPassword">*/}
        {/*  <Form.Label>Password</Form.Label>*/}
        {/*  <Form.Control*/}
        {/*    type="text"*/}
        {/*    defaultValue={props.account.password}*/}
        {/*    required*/}
        {/*  />*/}
        {/*</Form.Group>*/}

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              id="editPassword"
              defaultValue={props.account.password}
              disabled
            />
            <Button variant="outline-secondary" onClick={handlePasswordChange}>
              Generate new
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label>Investor password</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.account.investorPassword}
            disabled
          />
        </Form.Group>

        <Form.Group controlId="editLeverage">
          <Form.Label>Leverage</Form.Label>
          <Form.Control
            as="select"
            className="roundedBox"
            defaultValue={props.account.leverage}
            required
          >
            <option value="50">50:1</option>
            <option value="100">100:1</option>
            <option value="200">200:1</option>
            <option value="300">300:1</option>
            <option value="400">400:1</option>
            <option value="500">500:1</option>
          </Form.Control>
        </Form.Group>

        <Form.Text style={{ color: "red" }}>
          {props.errors.server}
          {state.addAccountErrors}
        </Form.Text>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={() =>
            props.handleModalClose("showAddEditAccountDetailsModal")
          }
        >
          Cancel
        </Button>
        {/*<Button*/}
        {/*  type="button"*/}
        {/*  variant="danger"*/}
        {/*  onClick={() => handleRequestCloseAccount(props.account._id)}*/}
        {/*>*/}
        {/*  CLOSE*/}
        {/*</Button>*/}
        <Button
          type="submit"
          className="darkBlueButton"
          style={{
            fontWeight: "normal",
          }}
          onClick={handleEditAccountDetails}
          disabled={btnDisabled}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Submit
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AccountDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  adminCreateSubAccount: PropTypes.func.isRequired,
  adminUpdateAccount: PropTypes.func.isRequired,
  adminCheckAccountPasswordChangeStatus: PropTypes.func.isRequired,
  // requestCloseAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  adminCreateSubAccount,
  adminUpdateAccount,
  adminCheckAccountPasswordChangeStatus,
  // requestCloseAccount,
})(AccountDetails);
